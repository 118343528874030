import React from "react";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../../components/head.js";
import Layout from "../../../components/layout";

// var choice = Math.floor(Math.random() * 2);
// var choice = 0;

const Benji = () => {
  return (
    <Layout>
      <Head title="Benji Friedman" />
      <h3>Benji Friedman</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Berkeley, CA
      </h4>
      <p>
        <em>better music coming soon...</em>
      </p>
      {/* <figure>
        <figcaption>Dog Song</figcaption>
        <audio
          controls
          src="https://res.cloudinary.com/benjifri/video/upload/v1660522132/artnow/benji%20friedman/dog_song.m4a"
        >
          <track kind="captions"></track>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </figure>
      <figure>
        <figcaption>Modern Lonely Times</figcaption>
        <audio
          controls
          src="https://res.cloudinary.com/benjifri/video/upload/v1660522132/artnow/benji%20friedman/modern_lonely_times.m4a"
        >
          <track kind="captions"></track>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </figure> */}
    </Layout>
  );
};

export default Benji;
